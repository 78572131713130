import gql from 'graphql-tag'

export const WELCOME_SCREEN = gql`
query WelcomeScreen {
  welcomeScreen {
    bsdToSign
    incomingCollects
    ... on ProducerWelcomeScreenGraphql {
      collectWithIncidents
    }
    ... on RecyclerWelcomeScreenGraphql {
      materialPriceToUpdate
      materialWithoutPrice
      collectsWithMissingFields
    }
  }
}
`
