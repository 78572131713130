import gql from 'graphql-tag'

export const COLLECTED_CONTAINERS = gql`
query CollectedContainers($filters: CollectedContainerGraphqlFilters, $pagination: PaginationArgs) {
  collectedContainers(filters: $filters, pagination: $pagination) {
    collection {
      id
      quantity
      treatmentCode
      unit
      collect {
        id
        startsAt
        endsAt
        status
        npsRating {
          rating
        }
        recycler { 
          name
        }
      }
      documents {
        collection {
          signedUrl
          id
          subtype
          extension
          name
          ... on CollectedContainerDocumentGraphql {
            invoiceCertainty
          }
          client { 
            id
          }
        }
      }
      BSD {
        id
        state
        tdState
      }
      container {
        id
        name
      }
      material {
        id
        name
        pricingForCurrentRecycler {
          price
          vat
        }
        documents { 
          collection {
            id
            signedUrl
          }
        }
      }
    }
  }
}
`

export const UPDATE_COLLECTED_CONTAINER = gql`
mutation UpdateCollectedContainer($input: UpdateCollectedContainerInput!) {
  updateCollectedContainer(input: $input) {
    quantity
    treatmentCode
    id
  }
}
`

export const COLLECTED_CONTAINER_DETAILS_SUMMARY = gql`
query CollectedContainerDetailsSummary($input: PricingSummaryInputGraphql, $filters: CollectedContainerGraphqlFilters) {
  collectedContainers(filters: $filters) {
    __typename
    collection {
      id
      ...CollectedContainerPricingSummaryGraphql
    }
  }
}
`
