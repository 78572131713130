import { driver } from 'driver.js'

export const hasDemoBeenSeen = useStorage('hasDemoBeenSeen', false)
export const useFakeData = ref(false)
export const isDemoAccount = ref(false)

export function useDriver() {
  const { isRecycler, user, isAdmin } = storeToRefs(useAuthStore())
  const router = useRouter()
  const { t } = useI18n()

  const RECYCLER_STEPS = [
    {
      element: '#navbar-element-profile',
      popover: {
        title: t('global.profile'),
        description: t('tour.profile_desc'),
        onNextClick: async () => {
          await router.push('/profile-settings')
          setTimeout(() => {
            driverObj.value.moveNext()
          }, 100)
        },
      },
    },
    {
      element: '#profile-td',
      popover: {
        title: 'TrackDéchets',
        description: t('tour.td_connect'),
        onNextClick: async () => {
          if (isMobile.value)
            await router.push('/pricing')
          setTimeout(() => {
            driverObj.value.moveNext()
          }, 100)
        },
      },
      mobile: true,
    },
    {
      element: '#navbar-element-prices',
      popover: {
        title: t('tour.pricing_title'),
        description: t('tour.pricing_desc'),
        onNextClick: async () => {
          await router.push('/pricing')
          setTimeout(() => {
            driverObj.value.moveNext()
          }, 100)
        },
      },
    },
    {
      element: '#create-pricing-button',
      popover: {
        description: t('tour.create_pricing'),
        onPrevClick: async () => {
          await router.push('/profile-settings')
          setTimeout(() => {
            driverObj.value.movePrevious()
          }, 100)
        },
      },
      mobile: true,
    },
    {
      element: '#pricing-table',
      popover: {
        description: t('tour.pricing_table'),
        onNextClick: async () => {
          if (isMobile.value)
            await router.push('/collects/')
          setTimeout(() => {
            driverObj.value.moveNext()
          }, 100)
        },
      },
      mobile: true,
    },
    {
      element: '#navbar-element-collects',
      popover: {
        title: t('global.collects'),
        onNextClick: async () => {
          await router.push('/collects/')
          setTimeout(() => {
            driverObj.value.moveNext()
          }, 100)
        },
      },
    },
    {
      element: '#collects-recycler-table',
      popover: {
        description: t('tour.collect_desc'),
        onNextClick: async () => {
          await router.push('/collects/814')
          setTimeout(() => {
            driverObj.value.moveNext()
          }, 100)
        },
        onPrevClick: async () => {
          await router.push('/pricing')
          setTimeout(() => {
            driverObj.value.movePrevious()
          }, 100)
        },
      },
      mobile: true,
    },
    {
      element: '#collect-details',
      popover: {
        description: t('tour.collect_details'),
        onPrevClick: async () => {
          await router.push('/collects/')
          setTimeout(() => {
            driverObj.value.movePrevious()
          }, 100)
        },
      },
      mobile: true,
    },
    {
      element: '#add-collect-to-calendar',
      popover: {
        description: t('tour.collect_add_to_calendar'),
      },
      mobile: true,
    },
    {
      element: '#sign-bsd-button',
      popover: {
        description: t('tour.collect_sign_bsd'),
        onNextClick: async () => {
          if (isMobile.value)
            await router.push('/my-documents')
          setTimeout(() => {
            driverObj.value.moveNext()
          }, 100)
        },
      },
      mobile: true,
    },
    {
      element: '#navbar-element-documents',
      popover: {
        title: t('tour.documents_title'),
        onNextClick: async () => {
          await router.push('/my-documents/')
          setTimeout(() => {
            driverObj.value.moveNext()
          }, 100)
        },
        onPrevClick: async () => {
          await router.push('/collects/814')
          setTimeout(() => {
            driverObj.value.movePrevious()
          }, 100)
        },
      },
    },
    {
      element: '#documents-table',
      popover: {
        description: t('tour.documents_desc'),
        onNextClick: async () => {
          await router.push('/')
          setTimeout(() => {
            driverObj.value.moveNext()
          }, 200)
        },
        onPrevClick: async () => {
          if (isMobile.value)
            await router.push('/collects/814')
          setTimeout(() => {
            driverObj.value.movePrevious()
          }, 100)
        },
      },
      mobile: true,
    },
    {
      element: '#dashboard',
      popover: {
        description: t('tour.dashboard'),
        onPrevClick: async () => {
          await router.push('/my-documents/')
          setTimeout(() => {
            driverObj.value.movePrevious()
          }, 100)
        },
      },
      mobile: true,
    },
  ]

  const PRODUCER_STEPS = [
    {
      element: '#navbar-element-profile',
      popover: {
        title: t('global.profile'),
        description: t('tour.profile_desc'),
        onNextClick: async () => {
          await router.push('/profile-settings')
          driverObj.value.moveNext()
        },
      },
    },
    {
      element: '#profile-td',
      popover: {
        title: 'TrackDéchets',
        description: t('tour.td_connect'),
        onNextClick: () => {
          if (isMobile.value) {
            setTimeout(async () => {
              await router.push('/compliances')
              driverObj.value.moveNext()
            }, 100)
          }
          else {
            driverObj.value.moveNext()
          }
        },
      },
      mobile: true,
    },
    {
      element: '#navbar-element-compliances',
      popover: {
        title: t('tour.compliance_title'),
        onNextClick: () => {
          setTimeout(async () => {
            await router.push('/compliances')
            driverObj.value.moveNext()
          }, 100)
        },
        onPrevClick: async () => {
          await router.push('/profile-settings')
          setTimeout(() => {
            driverObj.value.movePrevious()
          }, 100)
        },
      },
      mobile: false,
    },
    {
      element: '#compliance',
      popover: {
        description: t('tour.compliance_desc'),
        onNextClick: async () => {
          if (isMobile.value)
            await router.push('/my-materials')
          setTimeout(() => {
            driverObj.value.moveNext()
          }, 100)
        },
        onPrevClick: async () => {
          if (isMobile.value) {
            await router.push('/profile-settings')
            setTimeout(() => {
              driverObj.value.movePrevious()
            }, 100)
          }
          else {
            driverObj.value.movePrevious()
          }
        },
      },
      mobile: true,
    },
    {
      element: '#navbar-element-materials',
      popover: {
        title: t('tour.your_waste'),
        description: t('tour.materials_desc'),
        onNextClick: async () => {
          await router.push('/my-materials')
          setTimeout(() => {
            driverObj.value.moveNext()
          }, 100)
        },
      },
    },
    {
      element: '#my-materials-table',
      popover: {
        description: t('tour.materials_desc2'),
        onNextClick: async () => {
          if (isMobile.value)
            await router.push('/collects/')
          setTimeout(() => {
            driverObj.value.moveNext()
          }, 200)
        },
        onPrevClick: async () => {
          await router.push('/compliances')
          setTimeout(() => {
            driverObj.value.movePrevious()
          }, 100)
        },
      },
      mobile: true,
    },
    {
      element: '#navbar-element-collects',
      popover: {
        title: t('global.collects'),
        description: t('tour.collect_desc2'),
        onNextClick: async () => {
          await router.push('/collects/')
          setTimeout(() => {
            driverObj.value.moveNext()
          }, 100)
        },
      },
    },
    {
      element: '#create-collect-button',
      popover: {
        description: t('tour.create_collect'),
        onPrevClick: async () => {
          await router.push('/my-materials')
          setTimeout(() => {
            driverObj.value.movePrevious()
          }, 100)
        },
      },
      mobile: true,
    },
    {
      element: '#collects-producer-table',
      popover: {
        description: t('tour.wp_collect_desc'),
        onNextClick: async () => {
          if (isMobile.value)
            await router.push('/my-documents/')
          setTimeout(() => {
            driverObj.value.moveNext()
          }, 100)
        },
      },
      mobile: true,
    },
    {
      element: '#navbar-element-documents',
      popover: {
        title: t('tour.documents_title'),
        onNextClick: async () => {
          await router.push('/my-documents/')
          setTimeout(() => {
            driverObj.value.moveNext()
          }, 100)
        },
        onPrevClick: async () => {
          await router.push('/collects/')
          setTimeout(() => {
            driverObj.value.movePrevious()
          }, 100)
        },
      },
    },
    {
      element: '#document-invoice-card',
      popover: {
        description: t('tour.documents_desc'),
        onNextClick: async () => {
          if (isMobile.value)
            await router.push('/reports/')
          setTimeout(() => {
            driverObj.value.moveNext()
          }, 100)
        },
        onPrevClick: async () => {
          if (isMobile.value)
            await router.push('/')
          setTimeout(() => {
            driverObj.value.movePrevious()
          }, 100)
        },
      },
      mobile: true,
    },
    {
      element: '#navbar-element-reports',
      popover: {
        title: t('tour.report_title'),
        onNextClick: async () => {
          await router.push('/reports/')
          setTimeout(() => {
            driverObj.value.moveNext()
          }, 100)
        },
        onPrevClick: async () => {
          await router.push('/my-documents/')
          setTimeout(() => {
            driverObj.value.movePrevious()
          }, 100)
        },
      },
    },
    {
      element: '#reports-content',
      popover: {
        description: t('tour.report_desc'),
        onNextClick: async () => {
          await router.push('/')
          setTimeout(() => {
            driverObj.value.moveNext()
          }, 200)
        },
        onPrevClick: async () => {
          if (isMobile.value)
            await router.push('/my-documents/')
          setTimeout(() => {
            driverObj.value.movePrevious()
          }, 100)
        },
      },
      mobile: true,
    },
    {
      element: '#dashboard',
      popover: {
        description: t('tour.dashboard'),
        onPrevClick: async () => {
          await router.push('/reports/')
          setTimeout(() => {
            driverObj.value.movePrevious()
          }, 100)
        },
      },
      mobile: true,
    },
  ]

  const steps = computed(() => {
    if (isRecycler.value) {
      if (isMobile.value)
        return RECYCLER_STEPS.filter(step => step.mobile)
      return RECYCLER_STEPS
    }
    else {
      if (isMobile.value)
        return PRODUCER_STEPS.filter(step => step.mobile)
      return PRODUCER_STEPS
    }
  })
  const driverObj = computed(() => driver({
    showProgress: true,
    smoothScroll: true,
    onPopoverRender(popover) {
      popover.nextButton.classList.add('!btn-primary')
      popover.nextButton.classList.add('!btn')
      popover.nextButton.classList.add('!btn-sm')
      popover.nextButton.style.textShadow = 'none'

      popover.previousButton.classList.add('!btn')
      popover.previousButton.classList.add('!btn-sm')
      popover.previousButton.style.textShadow = 'none'
    },
    steps: steps.value,
    onDestroyed() {
      hasDemoBeenSeen.value = true
      stopDriver()
    },
  }))

  function startDriver(startDemo: boolean = false) {
    if (isAdmin.value || isMobile.value)
      return
    isDemoAccount.value = startDemo
    // Launch demo if the user has not seen it or if it is a demo account
    if (!hasDemoBeenSeen.value || startDemo) {
      useFakeData.value = true
      setTimeout(() => {
        driverObj.value.drive()
      }, 500)
    }
  }

  function stopDriver() {
    driverObj.value.destroy()
    if (user.value.user.email === 'demowr@cinqo.app' || user.value.user.email === 'demowp@cinqo.app') {
      router.push('/auth/login')
    }
    else {
      location.reload()
    }
    useFakeData.value = false
    isDemoAccount.value = false
  }

  return {
    driverObj,
    startDriver,
    stopDriver,
  }
}
