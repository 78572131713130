import type { ApolloQueryResult } from '@apollo/client'
import type { User } from 'firebase/auth'
import type { CurrentUserGraphql, Query, SignUpInputGraphql } from '~/types/graphql-backend-types/gql-types'
import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  getAuth,
  linkWithCredential,
  signInWithCustomToken,
} from 'firebase/auth'
import { cleanupFirebaseAuthObserver, firebaseAuthObserver, logout } from '~/controllers/authentication'
import { GET_CURRENT_USER, GET_OVERLAYED_CLIENT_TOKEN, SIGN_UP } from '~/queries/auth'
import { fb_auth } from '~/services/firebase'
import { ClientType } from '~/types/graphql-backend-types/gql-types'

export type provider = 'firebase' | 'microsoft.com' | 'google' | 'custom'

interface AuthState {
  provider: provider
  state: AuthStatus
  initialized: boolean
  firebaseUserPopulated: boolean
  onboardingRedirectPath: string | null
}

export enum AuthStatus {
  SIGNED_OUT = 'SIGNED_OUT',
  SSO_SIGN_UP = 'SSO_SIGN_UP',
  SSO_SIGN_IN = 'SSO_SIGN_IN',
  SIGN_UP = 'SIGN_UP',
  SIGN_IN = 'SIGN_IN',
  SIGNED_IN = 'SIGNED_IN',
}

export const authState: AuthState = reactive({
  provider: 'firebase',
  state: AuthStatus.SIGNED_OUT,
  initialized: false,
  firebaseUserPopulated: false,
  onboardingRedirectPath: null,
})

// This variable is used in case of failed SSO sign up, getAuth().currentUser is null so we need
// the user set in firebase observer to set the EmailProvider
export const beforeAuthChangedUser = ref<User>()

export const useAuthStore = defineStore('auth', () => {
  const { mutate, query } = useGqlMikro()
  const { overlayedUserToken } = storeToRefs(useAdminTokenStore())
  const router = useRouter()

  // Password used for the sign up flow => need the ref to allow EmailAuthProvider
  const tempPassword = ref('')
  const user = ref<CurrentUserGraphql>() as Ref<CurrentUserGraphql>

  const isRecycler = computed(() => {
    // @ts-expect-error __typename is accessible in the schema
    return user.value?.client?.__typename === 'RecyclerGraphql' || isAdmin.value
  })

  const isProducer = computed(() => {
    // @ts-expect-error __typename is accessible in the schema
    return user.value?.client?.__typename === 'ProducerGraphql' || isAdmin.value
  })

  const isAdmin = computed(() => {
    return user.value?.user?.role === 'admin_app'
  })

  const signupInput = reactive<SignUpInputGraphql>({
    userId: '',
    email: '',
    firstName: '',
    lastName: '',
    function: '',
    type: ClientType.Producer,
    siret: '',
    companyName: '',
    phoneNumber: '',
    onboardingNeeds: [],
  })

  async function refreshToken() {
    if (fb_auth.currentUser) {
      token.value = await fb_auth.currentUser.getIdToken(true)
    }
  }

  async function getCurrentUser() {
    try {
      const { data, errors } = await query({
        query: GET_CURRENT_USER,
        variables: { input: { dark: isDark.value } },
      }) as ApolloQueryResult<Query>

      if (errors && errors.length > 0) {
        // Retry once with new token
        await refreshToken()

        // Use a new call instead of recursion
        const retryResult = await query({
          query: GET_CURRENT_USER,
          variables: { input: { dark: isDark.value } },
        }) as ApolloQueryResult<Query>

        if (retryResult.errors && retryResult.errors.length > 0) {
          await logout()
          router.push('/auth/login')
          return {}
        }

        user.value = JSON.parse(JSON.stringify(retryResult.data.me))
        return retryResult.data?.me?.client ?? {}
      }

      user.value = JSON.parse(JSON.stringify(data.me))
      return data?.me?.client ?? {}
    }
    catch {
      // Handle any other errors
      await logout()
      router.push('/auth/login')
      return {}
    }
  }

  async function signUp(input: SignUpInputGraphql, dontRedirect = false): Promise<void> {
    try {
      // Handle Sign Up
      if (authState.state === AuthStatus.SIGN_UP) {
        try {
          const { user } = await createUserWithEmailAndPassword(fb_auth, input.email, input.password as string)
          input.userId = user.uid
        }
        catch (error: any) {
          // User already exists in firebase
          if (error.code === 'auth/email-already-in-use') {
            authState.state = AuthStatus.SSO_SIGN_UP
            console.warn('User already exists in firebase, proceed to classic sign-up')
          }
          else {
            addToast('', { type: 'error' })
            return
          }
        }
      }

      const { errors } = await mutate({
        mutation: SIGN_UP,
        variables: { input },
      })

      if (errors && errors.length > 0) {
        throw errors[0]
      }

      // Handle SSO Sign Up
      if (authState.state === AuthStatus.SSO_SIGN_UP) {
        try {
          await linkEmailAuthProviderToUser(signupInput.email, tempPassword.value)
        }
        catch (error) {
          console.error('ERROR provider', error)
        }
      }

      tempPassword.value = ''
      // Needed for AuthProvider to fetch current user
      authState.firebaseUserPopulated = true
      if (!dontRedirect)
        router.push('/')
      reset()
    }
    catch (err) {
      authState.onboardingRedirectPath = null
      throw err
    }
  }

  async function linkEmailAuthProviderToUser(email: string, password: string) {
    const user = getAuth().currentUser ?? beforeAuthChangedUser.value

    const credential = EmailAuthProvider.credential(
      email,
      password,
    )

    try {
      if (user) {
        const userCred = await linkWithCredential(user, credential)
        token.value = await userCred.user.getIdToken()
      }
      else {
        throw new Error('user is null')
      }
    }
    catch {
      console.error('EMAIL PROVIDER LINK FAILED')
    }
  }

  async function stealUserIdentity(userIdToUsurpate: string, setOverlayedToken: boolean): Promise<void> {
    const { data, errors } = await mutate({
      mutation: GET_OVERLAYED_CLIENT_TOKEN,
      variables: { input: { userId: userIdToUsurpate } },
    })

    if (errors && errors.length > 0) {
      throw errors
    }

    // First clear the token store and user state - this prevents race conditions
    token.value = ''
    authState.firebaseUserPopulated = false
    authState.state = AuthStatus.SIGNED_OUT
    // @ts-expect-error cant assign undefined to CurrentUserGraphql
    user.value = undefined
    // Temporarily pause auth observers to prevent race conditions
    cleanupFirebaseAuthObserver()

    try {
      // Sign in with the custom token and wait for it to complete
      const userCredential = await signInWithCustomToken(getAuth(), data!.logAs!.userJWT)
      // Force an immediate token refresh to ensure we have the latest token
      const freshToken = await userCredential.user.getIdToken(true)
      // Store in our token store
      token.value = freshToken
      if (setOverlayedToken)
        overlayedUserToken.value = freshToken
      else
        overlayedUserToken.value = ''

      // Restart auth observers after token is properly set
      await firebaseAuthObserver()
    }
    catch (error) {
      console.error('Error in stealUserIdentity:', error)
      // Restart auth observers in case of error
      await firebaseAuthObserver()
      throw error
    }
  }

  function reset() {
    signupInput.email = ''
    signupInput.firstName = ''
    signupInput.lastName = ''
    signupInput.function = ''
    signupInput.type = ClientType.Producer
    signupInput.siret = ''
    signupInput.companyName = ''
    signupInput.phoneNumber = ''
    signupInput.onboardingNeeds = []
  }

  return {
    signUp,
    stealUserIdentity,
    getCurrentUser,
    user,
    isAdmin,
    isProducer,
    isRecycler,
    signupInput,
    tempPassword,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
