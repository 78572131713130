export const isDark = useDark({
  storageKey: 'cinqo-theme-appearance',
  selector: 'html',
  attribute: 'data-theme',
  valueDark: 'dracula',
  valueLight: 'mytheme',
})

export const toggleDark = useToggle(isDark)

export const logo = computed(() => isDark.value ? '/assets/logo-light.svg' : '/assets/logo-dark.svg')
export const picto = computed(() => isDark.value ? '/assets/picto-light.svg' : '/assets/picto-dark.svg')
