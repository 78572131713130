import gql from 'graphql-tag'

export const DASHBOARD_QUERY = gql`
  query Dashboard($input: ClientCustomDashboardInputGraphql) {
    getDashboardUrl(input: $input)
  }
`

export const IMPORT_LEGACY_DATA = gql`
mutation ImportLegacyData($input: LegacyDataImportInput!) {
  importLegacyDataSpreadsheet(input: $input)
}
`
