<script setup lang="ts">
import { CircleUserRoundIcon } from 'lucide-vue-next'
import { computed } from 'vue'

interface Props {
  src?: string
  alt?: string
  placeholder?: string
  status?: 'online' | 'offline'
  size?: 'sm' | 'md' | 'lg'
}

const props = withDefaults(defineProps<Props>(), {
  alt: '',
  size: 'md',
})

const sizeClasses = computed(() => ({
  'w-8 h-8': props.size === 'sm',
  'w-12 h-12': props.size === 'md',
  'w-16 h-16': props.size === 'lg',
}))

const statusClasses = computed(() => ({
  online: props.status === 'online',
  offline: props.status === 'offline',
}))

const hasPlaceholder = computed(() => !props.src && props.placeholder)

const initials = computed(() => {
  if (!props.placeholder)
    return ''
  return props.placeholder
    .split(' ')
    .map(word => word[0])
    .join('')
    .toUpperCase()
    .slice(0, 2)
})
</script>

<template>
  <div class="avatar" :class="[sizeClasses, statusClasses]">
    <div class="rounded-full">
      <template v-if="hasPlaceholder">
        <div class="placeholder bg-neutral text-neutral-content">
          <span>{{ initials }}</span>
        </div>
      </template>
      <template v-else>
        <img v-if="src" :src="src" :alt="alt">
        <CircleUserRoundIcon v-else :size="32" :stroke-width="1.25" />
      </template>
    </div>
  </div>
</template>

<style scoped>
.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-weight: 500;
}
</style>
