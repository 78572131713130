<script setup lang="ts">
import { MessageSquareIcon, SmartphoneIcon } from 'lucide-vue-next'
import accountManager from '/assets/account-manager.png'

const { t } = useI18n()
</script>

<template>
  <div class="card shadow-md bg-base-100 card-normal md:w-80 w-full">
    <div class="card-body">
      <h2 class="card-title flex gap-4">
        <div>
          <span class="text-sm">{{ t('global.account_manager') }}</span>
          <br>
          <span class="text-xl">Laure de Bokay</span>
          <br>
          <span class="text-primary text-xs">laure@cinqo.app</span>
          <div class="text-xs">
            <a class="text-primary underline underline-offset-2 cursor-pointer" href="tel:0670561415">
              06 70 56 14 15
            </a>
          </div>
        </div>
        <Avatar :src="accountManager" class="ml-auto" status="online" />
      </h2>

      <p class="flex flex-wrap leading-7">
        {{ t('global.account_manager_content') }}
      </p>

      <div class="card-actions flex gap-2">
        <a href="https://calendly.com/laure-cinqo/30min" target="_blank" class="flex w-full">
          <EButton
            class="btn-primary btn-outline grow"
            size="sm"
            :label="t('global.book_a_call')"
            :icon="SmartphoneIcon"
          />
        </a>
        <EButton
          class="btn-primary btn-outline grow"
          size="sm"
          :label="t('global.chat')"
          :icon="MessageSquareIcon"
          @click="toggleCrispChat()"
        />
      </div>
    </div>
  </div>
</template>
