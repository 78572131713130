import { gql } from '@apollo/client/core'

export const SIGN_BSD = gql`
mutation SignBsd($input: SignBSDInput!) {
  signBSDs(input: $input) {
    id
    state
    tdState
  }
}
`

export const SIGN_ALL_BSDS = gql`
mutation SignAllCollectBSDs($input: SignAllCollectBSDsInput!) {
  signAllCollectBSDs(input: $input) {
    id
    state
    tdState
  }
}
`

export const COLLECT_DETAILS_SUMMARY = gql`
query CollectDetailsSummary($input: PricingSummaryInputGraphql, $filters: CollectGraphqlFilters) {
  collects(filters: $filters) {
    __typename
    collection {
      id
      ...CollectPricingSummaryGraphql
    }
  }
}
`

export const COLLECTS_QUERY_SINGLE = gql`
query CollectSingle($pagination: PaginationArgs!, $filters: CollectGraphqlFilters, $isAdmin: Boolean!) {
  collects(pagination: $pagination, filters: $filters) {
    collection {
      ...AdminFields @include(if: $isAdmin)
      notes
      status
      id
      transportPrice
      startsAt
      endsAt
      licensePlate
      npsRating {
        additionalDetails
        rating
      }
      BSDs {
        count
        collection {
          id
          tdState
          state
        }
      }
      client {
        name
        id
      }
      collectedContainers {
        collection {
          id
          quantity
          treatmentCode
          unit
          documents {
            collection {
              signedUrl
              name
              type
              id
              extension
              ... on CollectedContainerDocumentGraphql {
                subtype
              }
              client {
                id
              }
            }
          }
          BSD {
            state
            id
            tdState
          }
          container {
            name
            id
          }
          material {
            id
            name
            documents {
              collection {
                signedUrl
                name
                id
                client {
                  id
                }
              }
            }
            pricingForCurrentRecycler {
              id
              price
              vat
            }
          }
        }
      }
      recycler {
        id
        name
      }
    }
  }
}
`

export const COLLECTS_WITH_MISSING_FIELDS = gql`
query CollectsWithMissingFields {
  collectsWithMissingFields {
    licensePlate
    createdAt
    id
    startsAt
    endsAt
    collectedContainers {
      collection {
        quantity
        unit
        id
        treatmentCode
        container {
          id
          name
          material {
            id
            name
          }
        }
      }
    }
    client { 
      id
      name
    }
  }
}
`

export const COLLECTS_QUERY_LIST = gql`
query CollectList($pagination: PaginationArgs!, $filters: CollectGraphqlFilters) {
  collects(pagination: $pagination, filters: $filters) {
    count
    collection {
      status
      id
      startsAt
      endsAt
      createdAt
      npsRating {
        additionalDetails
        rating
      }
      client {
        name
        id
      }
      collectedContainers {
        collection {
          id
          material {
            id
            name
            documents {
              collection {
                signedUrl
                client {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export const CREATE_COLLECT_QUERY = gql`
mutation CreateCollect($input: CreateCollectGraphql!) {
  createCollect(input: $input) {
    id
    collectedContainers {
      collection {
        id
        material {
          id
          documents {
            collection {
              signedUrl
            }
          }
        }
        container {
          id
        }
      }
    }
  }
}
`

export const UPDATE_COLLECT_QUERY = gql`
mutation UpdateCollectInformations($input: UpdateCollectInformationsGraphql!) {
  updateCollectInformations(input: $input) {
    id
    status
    startsAt
    endsAt
    notes
    licensePlate
    transportPrice
    referent {
      firstName
      id
      lastName
    }
  }
}
`

export const BO_COLLECTS_QUERY_LIST = gql`
query BOCollectList($pagination: PaginationArgs!, $filters: CollectGraphqlFilters) {
  collects(pagination: $pagination, filters: $filters) {
    count
    collection {
      id
      status
      createdAt
      endsAt
      startsAt
      isInvoiceable {
        alreadyInvoiced
        errors
        result
      }
      client {
        name
        id
      }
      ...AdminFields
      collectedContainers {
        collection {
          id
          quantity
          material {
            id
            name
          }
          container {
            name
            id
          }
        }
      }
      recycler {
        id
        name
      }
    }
  }
}
`

export const CANCEL_COLLECT = gql`
mutation CancelCollect($input: CancelCollectInputGraphql!) {
  cancelCollect(input: $input) {
    status
    id
  }
}
`

export const RATE_COLLECT_NPS = gql`
mutation RateCollectNps($input: RateCollectNPSInputGraphql!) {
  rateCollectNPS(input: $input) {
    id
    npsRating {
      additionalDetails
      rating
    }
  }
}
`

export const GET_REFERENTS = gql`
query Referents {
  referents {
    collection {
      firstName
      id
      lastName
    }
  }
}
`
