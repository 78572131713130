import { gql } from '@apollo/client/core'

export const GET_CLIENTS = gql`
query Clients($pagination: PaginationArgs!, $filters: ClientFilterGraphql!) {
  clients(pagination: $pagination, filters: $filters) {
    count
    collection {
      type
      id
      contactFirstName
      contactLastName
      iban
      isConnectedToTrackdechets
      name
      phoneNumber
      siret
      slug
      bic
      onboardingNeeds
      documents { 
        collection {
          name
          signedUrl
          type
          id
          extension
          createdAt
        }
      }
      sites {
        collection {
          name
          id
        }
      }
      ... on ProducerGraphql {
        paysMonthlyFees
        recyclers {
          collection {
            id
            name
          }
        }
      }
      ... on RecyclerGraphql {
        percentageRecyclerRevshare
        paysMonthlyFees
        transporterReceipt
      }
      autosignEnabledForTrackdechets
    }
  }
}
`

export const GET_CLIENT = gql`
query Client($pagination: PaginationArgs!, $filters: ClientFilterGraphql!) {
  clients(pagination: $pagination, filters: $filters) {
    count
    collection {
      id
      type
      contactFirstName
      contactLastName
      iban
      isConnectedToTrackdechets
      autosignEnabledForTrackdechets
      name
      phoneNumber
      siret
      slug
      bic
      documents { 
        collection {
          name
          signedUrl
          type
          id
          extension
          createdAt
        }
      }
      sites {
        collection {
          name
          id
        }
      }
      ... on ProducerGraphql {
        paysMonthlyFees
        recyclers {
          collection {
            id
            name
            contactFirstName
            contactLastName
          }
        }
      }
      ... on RecyclerGraphql {
        percentageRecyclerRevshare
        paysMonthlyFees
        transporterReceipt
        producers {
          collection {
            id
            name
            contactFirstName
            contactLastName
          }
        }
      }
    }
  }
}
`

export const GET_CLIENTS_FOR_SELECT = gql`
query ClientsForSelect($pagination: PaginationArgs!, $filters: ClientFilterGraphql!) {
  clients(pagination: $pagination, filters: $filters) {
    collection {
      id
      contactFirstName
      contactLastName
      name
    }
  }
}
`

export const UPDATE_CLIENT = gql`
mutation UpdateClient($input: ClientInput!) {
  updateClient(input: $input) {
    id
    name
  }
}`

export const CREATE_CLIENT = gql`
mutation CreateClient($input: CreateClientInput!) {
  createClient(input: $input) {
    bic
    autosignEnabledForTrackdechets
    contactFirstName
    contactLastName
    iban
    id
    name
    phoneNumber
    siret
  }
}
`

export const ADD_PRODUCERS_TO_RECYCLER = gql`
mutation AddProducersToRecycler($input: ProducersToRecyclerInput!) {
  addProducersToRecycler(input: $input) {
    id
  }
}
`

export const ADD_RECYCLERS_TO_PRODUCER = gql`
mutation AddRecyclersToProducer($input: RecyclersToProducerInput!) {
  addRecyclersToProducer(input: $input) {
    id
  }
}
`

export const REMOVE_RECYCLERS_FROM_PRODUCER = gql`
mutation RemoveRecyclersFromProducer($input: RecyclersToProducerInput!) {
  removeRecyclersFromProducer(input: $input) {
    id
  }
}
`

export const REMOVE_PRODUCERS_FROM_RECYCLER = gql`
mutation RemoveProducersFromRecycler($input: ProducersToRecyclerInput!) {
  removeProducersFromRecycler(input: $input) {
    id
  }
}
`
