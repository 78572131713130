<script setup lang="ts">
import type {
  ClientCustomDashboardInputGraphql,
  Maybe,
  ProducerWelcomeScreenGraphql,
  RecyclerWelcomeScreenGraphql,
} from '~/types/graphql-backend-types/gql-types'
import { useLDFlag } from 'launchdarkly-vue-client-sdk'
import {
  FileClockIcon,
  FileIcon,
  FileMinus2Icon,
  InfoIcon,
  PartyPopperIcon,
  TargetIcon,
  TruckIcon,
} from 'lucide-vue-next'
import { WELCOME_SCREEN } from '~/queries/welcome-screen'
import blurredDashboard from '/assets/blurred_dashboard.png'
import blurredDashboardDark from '/assets/blurred_dashboard_dark.png'

const { t } = useI18n()
const { query } = useGqlMikro()
const router = useRouter()
// const collectCatchOnRoute = useRoute('/collects/catch-on')

const { getDashboardUrl } = useDashboard()
const { user, isRecycler, isAdmin, isProducer } = storeToRefs(useAuthStore())

// Feature flag
const welcomeScreenFeatureFlag = 'welcome-screen'
const welcomeScreenValue = useLDFlag(welcomeScreenFeatureFlag, false)
const loading = ref(true)
const iframeDashboardUrl = ref<Maybe<string | undefined>>('')

const recyclerWelcomeScreenData = reactive<RecyclerWelcomeScreenGraphql>({
  bsdToSign: 0,
  incomingCollects: 0,
  materialPriceToUpdate: 0,
  materialWithoutPrice: 0,
  collectsWithMissingFields: 0,
})

const producerWelcomeScreenData = reactive<ProducerWelcomeScreenGraphql>({
  bsdToSign: 0,
  incomingCollects: 0,
  collectWithIncidents: 0,
})

const dashboardCTALabel = computed(() => {
  if (isProducer.value) {
    return user.value.client.hasCollects ? t('dashboard.wp_waiting_for_recycler') : t('dashboard.wp_first_collect')
  }
  else if (isRecycler.value) {
    return t('dashboard.wr_view_collects')
  }
  return t('dashboard.wr_view_collects')
})

// TODO: need to type the to route
interface Cards { title: string, value: Maybe<number | undefined>, description: Record<number, string>, icon: Component, to?: any }

const recyclerWelcomeScreenCollectCards: ComputedRef<Cards[]> = computed(() => [
  {
    title: t('global.new_incoming_collects'),
    value: recyclerWelcomeScreenData.incomingCollects,
    description: {
      0: t('global.no_incoming_collects'),
      1: t('global.see_incoming_collects'),
    },
    to: '/collects?status=requestSent',
    icon: TruckIcon,
  },
  {
    title: t('catch_on.collect_with_missing_fields'),
    value: recyclerWelcomeScreenData.collectsWithMissingFields,
    description: {
      0: t('catch_on.no_collects_with_missing_fields'),
      1: t('catch_on.fill_collects_with_missing_fields'),
    },
    to: '/collects/catch-on',
    icon: FileMinus2Icon,
  },
  {
    title: t('global.bsd_that_needs_to_be_signed'),
    value: recyclerWelcomeScreenData.bsdToSign,
    description: {
      0: t('global.no_bsd_to_sign'),
      1: t('global.sign_bsd'),
    },
    icon: FileClockIcon,
  },
])

const producerWelcomeScreenCards: ComputedRef<Cards[]> = computed(() => [
  {
    title: t('global.goals'),
    value: undefined,
    description: {
      0: t('global.goals_follow'),
      1: t('global.goals_follow'),
    },
    to: '/compliances',
    icon: TargetIcon,
  },
  {
    title: t('global.incoming_collects'),
    value: producerWelcomeScreenData.incomingCollects,
    description: {
      0: t('global.no_incoming_collects'),
      1: t('global.see_incoming_collects'),
    },
    to: '/collects?status=requestSent',
    icon: TruckIcon,
  },
  {
    title: t('global.bsd_that_needs_to_be_signed'),
    value: producerWelcomeScreenData.bsdToSign,
    description: {
      0: t('global.no_bsd_to_sign'),
      1: t('global.sign_bsd'),
    },
    icon: FileIcon,
  },
])

const cardsData = computed(() => (isRecycler.value ? recyclerWelcomeScreenCollectCards.value : producerWelcomeScreenCards.value))

async function getWelcomeScreen() {
  const result = await query({
    query: WELCOME_SCREEN,
  })

  if (result.data === null && result.errors && result.errors.length > 0) {
    console.error(result.errors[0].message)
  }

  if (isRecycler.value)
    Object.assign(recyclerWelcomeScreenData, result.data.welcomeScreen)
  else
    Object.assign(producerWelcomeScreenData, result.data.welcomeScreen)
}

async function loadDashboard() {
  loading.value = true

  const dashboardPayload: ClientCustomDashboardInputGraphql = {
    dark: isDark.value,
  }

  iframeDashboardUrl.value = await getDashboardUrl(dashboardPayload)
  loading.value = false
}

onMounted(async () => {
  if (!welcomeScreenValue.value)
    router.push('/collects')
  await getWelcomeScreen()

  try {
    await loadDashboard()
  }
  catch {
    loading.value = false
  }
})
</script>

<template>
  <div v-if="welcomeScreenValue" class="overflow-y-auto grow flex flex-col">
    <div class="flex items-center gap-4 justify-center">
      <p class="text-center text-xl text-base-content font-semibold">
        {{ t("global.welome_screen", { name: user.user.firstName }) }}
      </p>
      <PartyPopperIcon :size="24" />
    </div>
    <div class="flex grow flex-col md:flex-row gap-4 pt-4 h-full overflow-auto">
      <div v-if="!isAdmin" class="flex flex-col gap-4 w-full md:max-w-80 max-h-screen overflow-y-auto">
        <AccountManager />
        <template v-for="metric in cardsData" :key="metric.title">
          <div class="gap-2 card card-normal bg-primary text-primary-content shadow hover:cursor-pointer">
            <div class="card-body">
              <h2 class="card-title">
                <component :is="metric.icon" :size="24" class="text-primary-content" />
                {{ metric.title }}
              </h2>

              <div class="stat-value flex justify-center pb-4">
                {{ metric.value }}
              </div>

              <div class="card-actions justify-center">
                <div v-if="metric.value! === 0" class="stat-desc text-primary-content">
                  {{ metric.description[0] }}
                </div>

                <div v-else class="mt-0 flex grow">
                  <EButton v-if="metric.description[1]" :label="metric.description[1]" class="btn-secondary btn-block" @click="router.push(metric.to)" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-if="!loading" id="dashboard" class="full-page-container bg-base-200">
        <div v-if="(user.client.hasCollects || isAdmin) && iframeDashboardUrl" class="h-full w-full">
          <iframe
            :src="iframeDashboardUrl"
            frameborder="0"
            allowtransparency
            class="full-page-iframe"
          />
        </div>
        <div v-else class="h-full w-full flex flex-col gap-4">
          <div class="card shadow-md bg-base-100 w-full mb-auto">
            <div class="card-body">
              <div class="card-title">
                <InfoIcon :size="24" class="text-base-content" />
                <h2>{{ t("global.dashboard_available_after_first_collect_title") }}</h2>
              </div>
              <p>{{ t('global.dashboard_available_after_first_collect_desc') }}</p>
            </div>
          </div>
          <div class="relative w-full flex-1 overflow-hidden">
            <img
              :src="isDark ? blurredDashboardDark : blurredDashboard"
              alt="dashboard_preview"
              class="rounded-xl object-cover w-full h-full"
            >
            <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <EButton
                class="btn-md btn-secondary"
                @click="router.push('/collects')"
              >
                {{ dashboardCTALabel }}
              </EButton>
            </div>
          </div>
        </div>
      </div>
      <Loader v-else />
    </div>
  </div>
</template>

<style>
.full-page-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.full-page-iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>

<route lang="yaml">
meta:
  layout: home
  requiresAuth: true
</route>
