import { VueQueryPlugin } from '@tanstack/vue-query'
import { DefaultApolloClient } from '@vue/apollo-composable'
import VueDatePicker from '@vuepic/vue-datepicker'
import { createHead } from '@vueuse/head'
import { LDPlugin } from 'launchdarkly-vue-client-sdk'
import { configure } from 'vee-validate'
import { setupLayouts } from 'virtual:generated-layouts'
import generatedRoutes from 'virtual:generated-pages'
import { createApp, h } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { createApolloClient } from '~/composables/graphql-nest'
import App from './App.vue'
import { setupSentry } from './config/sentry'
import clickOutside from './directives/vClickOutside'
import { i18n } from './modules/i18n'
import { authGuard } from './router/auth-guard'
import { getEnvironment } from './utils/env'
import '@vuepic/vue-datepicker/dist/main.css'
import './index.css'
import './styles/main.css'
import 'driver.js/dist/driver.css'

// Router setup
const routes = setupLayouts(generatedRoutes)

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

// Function to create and setup the app
async function setupApp() {
  const app = createApp({
    render: () => h(App),
  })

  // Plugin and directive registration
  app.component('VueDatePicker', VueDatePicker)
  app.directive('click-outside', clickOutside)

  // VueQuery (Tanstack)
  app.use(VueQueryPlugin, {
    enableDevtoolsV6Plugin: true,
  })

  // Sentry setup
  const environment = getEnvironment()
  if (environment !== 'development') {
    const sentryInitialized = setupSentry(app, environment)
    if (!sentryInitialized) {
      // Optionally set up alternative error tracking or logging here
      app.config.errorHandler = (err, instance, info) => {
        console.error('Vue Error:', err, info)
      }
    }
  }

  // Initialize Pinia first
  const pinia = createPinia()
  app.use(pinia)

  // i18n
  app.use(i18n)

  // Head
  const head = createHead()
  app.use(head)

  // Router
  app.use(router)

  // Apollo
  const apolloClient = createApolloClient(router)
  // Provide the Apollo client to the app
  app.provide(DefaultApolloClient, apolloClient)

  // Configure vee-validate gloabl config
  configure({
    validateOnBlur: false,
  })

  // Launchdarkly setup
  const ldProdKey = '67614c83fcd028095b04a491'
  const ldDevKey = '67614c83fcd028095b04a490'
  app.use(LDPlugin, {
    clientSideID: getEnvironment() === 'production' ? ldProdKey : ldDevKey,
  })

  router.beforeEach((to, from, next) => {
    // Auth guard
    if ((to.matched.some(record => record.meta.requiresAuth) || to.matched.some(record => record.meta.requiresAdmin)) && !useFakeData.value) {
      authGuard(to, from, next)
    }
    else {
      next()
    }
  })

  return app
}

// Check if an app is already mounted
const mountPoint = document.querySelector('#app')
if (mountPoint && !mountPoint.__vue_app__) {
  setupApp().then(app => app.mount('#app'))
}
else if (mountPoint && mountPoint.__vue_app__) {
  console.warn('An app is already mounted. Skipping new app creation.')
}
else {
  console.error('Mount point #app not found.')
}
