import type { Router } from "vue-router";
// apolloClient.ts
import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client/core";
import { storeToRefs } from "pinia";
// Make sure to import
import { getBackEndURL } from "~/config";
import { fragmentRegistry, registerFragment } from "~/queries/fragments";
import { fb_auth } from "~/services/firebase";
import { useAdminTokenStore } from "~/stores/adminToken"; // Assuming this is the correct import
import { createErrorLink } from "./useError";

// Token management
let tokenExpiryTime: number | null = null;
const TOKEN_EXPIRY_BUFFER = 5 * 60 * 1000; // 5 minutes buffer before actual expiry
export const token = useStorage("apollo-token", "");

export async function getToken(): Promise<string | undefined> {
  try {
    // Check if token is still valid (with buffer time)
    const now = Date.now();
    if (
      token.value &&
      tokenExpiryTime &&
      now < tokenExpiryTime - TOKEN_EXPIRY_BUFFER
    ) {
      return token.value;
    }
    const user = fb_auth.currentUser;
    if (!user) {
      console.log("No current user found when getting token");
      return undefined;
    }
    // Force token refresh if it's close to expiry
    const forceRefresh = tokenExpiryTime
      ? now > tokenExpiryTime - TOKEN_EXPIRY_BUFFER
      : false;
    token.value = await user.getIdToken(forceRefresh);
    // Update expiry time (tokens usually valid for 1 hour)
    if (token.value) {
      // 1000 ms * 60 seconds * 60 minutes = 1 hour in milliseconds
      tokenExpiryTime = Date.now() + 1000 * 60 * 60;
    }
    return token.value;
  } catch (error) {
    console.error("Error getting authentication token:", error);
    return undefined;
  }
}

// Create a function to initialize Apollo Client with router
export function createApolloClient(router: Router) {
  // Create the error link with router access
  const errorLink = createErrorLink(router);

  const httpLink = new HttpLink({ uri: `${getBackEndURL()}/graphql` });

  const authMiddlewareLink: any = async (operation: any, forward: any) => {
    try {
      const { overlayedUserToken } = storeToRefs(useAdminTokenStore());
      // If we have an overlayed token, use it directly without checking getToken
      if (overlayedUserToken.value) {
        operation.setContext({
          headers: { authorization: `Bearer ${overlayedUserToken.value}` },
        });
        return forward(operation);
      }
      // Otherwise get a fresh token
      const freshToken = await getToken();
      operation.setContext({
        headers: { authorization: freshToken ? `Bearer ${freshToken}` : "" },
      });
      return forward(operation);
    } catch (error) {
      console.error("Auth middleware error:", error);
      // Still forward the operation to allow error handling at the GraphQL level
      operation.setContext({ headers: { authorization: "" } });
      return forward(operation);
    }
  };

  registerFragment();

  return new ApolloClient({
    link: from([authMiddlewareLink, errorLink, httpLink]),
    cache: new InMemoryCache({ fragments: fragmentRegistry }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
      },
      query: {
        fetchPolicy: "network-only",
        errorPolicy: "all",
      },
      mutate: { errorPolicy: "all" },
    },
  });
}
