import { gql } from '@apollo/client/core'

export const GET_DOCUMENTS = gql`
query Documents($pagination: PaginationArgs, $filters: StoredDocumentFilters!) {
  documents(pagination: $pagination, filters: $filters) {
    count
    collection {
      createdAt
      extension
      externalReference
      id
      name
      signedUrl
      documentContentRaw
      type
      tags {
        name
        id
        color
      }
      client {
        id
      }
      ... on ClientDocumentGraphql {
        subtype
      }
      ... on CollectedContainerDocumentGraphql {
        subtype
      }
      ... on CollectDocumentGraphql {
        subtype
      }
    }
  }
}
`

export const GET_COLLECT_DOCUMENTS = gql`
query CollectDocuments($pagination: PaginationArgs, $filters: CollectDocumentFilters) {
  collectDocuments(pagination: $pagination, filters: $filters) {
    count
    collection {
      createdAt
      extension
      externalReference
      id
      name
      signedUrl
      type
      client {
        id
        name
      }
      ... on CollectDocumentGraphql {
        subtype
        collect {
          id
        }
      }
      ... on CollectedContainerDocumentGraphql {
        subtype
        collectedContainer {
          id
          collect {
            id
          }
        }
      }
    }
  }
}
`

export const EXTRACT_INVOICE_DATA = gql`
mutation ExtractInvoiceData($input: ExtractInvoiceDataInput!) {
  extractInvoiceData(input: $input) {
    signedUrl
    name
    id
    externalReference
    extension
    createdAt
    subtype
    type
    ... on CollectedContainerDocumentGraphql {
      invoiceCertainty
    }
  }
}
`

export const COLLECT_CSV_EXTRACT = gql`
  query CollectCSVExtract($input: CollectCSVExtractInput!) {
    collectCSVExtract(input: $input) {
      B64Csv
    }
  }
`

export const GENERATE_WASTE_REGISTER = gql`
query GenerateWasteRegister($input: GenerateWasteRegisterInput!) {
  generateWasteRegister(input: $input) {
    content
    name
  }
}
`

export const GENERATE_SIGNED_URL_FROM_ID = gql`
query GenerateSignedUrlFromId($filters: StoredDocumentFilters!) {
  documents(filters: $filters) {
    collection {
      signedUrl
      id
    }
  }
}
`

export const GENERRATE_SIGNED_UPLOAD_URL = gql`
mutation GenerateSignedUploadUrl($input: GenerateSignedUploadUrlInput!) {
  generateSignedUploadUrl(input: $input) {
    url
  }
}
`

export const STORE_DOCUMENT_REFERENCE = gql`
mutation StoreDocumentReference($input: StoreDocumentReferenceInput!) {
  storeDocumentReference(input: $input) {
    client {
      id
      contactLastName
      contactFirstName
      name
    }
    extension
    name
    type
    signedUrl
    ...on CollectDocumentGraphql {
      __typename
      subtype
    }
    id
  }
}
`

export const DELETE_STORED_DOCUMENT = gql`
mutation DeleteStoredDocument($input: DeleteStoredDocumentGraphqlInput!) {
  deleteStoredDocument(input: $input)
}
`

export const CREATE_DOCUMENT_TAG = gql`
mutation CreateDocumentTag($input: DocumentTagInput!) {
  createDocumentTag(input: $input) {
    id
  }
}
`

export const DELETE_DOCUMENT_TAG = gql`
mutation DeleteDocumentTag($input: DeleteDocumentTagInput!) {
  deleteDocumentTag(input: $input)
}
`

export const ADD_TAGS_TO_DOCUMENT = gql`
mutation AddTagsToDocument($input: DocumentTagAddToDocumentInput!) {
  addTagsToDocument(input: $input)
}
`

export const REMOVE_TAGS_FROM_DOCUMENT = gql`
mutation RemoveTagFromDocument($input: DocumentTagRemoveFromDocumentInput!) {
  removeTagFromDocument(input: $input)
}
`

export const GET_DOCUMENT_TAGS = gql`
query DocumentTags($filters: DocumentTagFilters, $pagination: PaginationArgs) {
  documentTags(filters: $filters, pagination: $pagination) {
    collection {
      name
      color
      id
    }
  }
}
`
