import { gql } from '@apollo/client/core'

export const CREATE_USER = gql`
mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    email
    firstName
    id
    lastName
    role
    client {
      name
    }
  }
}
`

export const UPDATE_USER = gql`
mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    disabled
    email
    firstName
    function
    id
    lastName
    role
    client {
      name
    }
  }
}
`

export const ACCEPT_CGU = gql`
mutation AcceptCGU {
  acceptCGU
}`
