import type { Maybe } from 'graphql/jsutils/Maybe'

import type {
  CompanyOnboardingGraphql,
  CreateUserInput,
  PaginationArgs,
  ProducerGraphql,
  UpdateUserInput,
  UserFilterGraphql,
  UserGraphql,
} from '~/types/graphql-backend-types/gql-types'
import { DELETE_STORED_DOCUMENT } from '~/queries/documents'
import { ACCEPT_CGU, CREATE_USER, UPDATE_USER } from '~/queries/user'
import {
  TOGGLE_TRACKDECHETS_AUTOSIGN,
  USERS_NEST_QUERY_LIST,
} from '~/queries/users'

export const useUsersStore = defineStore('users', () => {
  const { mutate, query } = useGqlMikro()
  const { user } = storeToRefs(useAuthStore())

  const users = ref<UserGraphql[]>([])
  const userSetForUpdate = ref<UserGraphql>()
  const usersCount = ref<number>(0)
  const page = ref<number>(1)

  const offset = computed(() => (page.value - 1) * 10)

  const filters = reactive<UserFilterGraphql>({
    search: '' as string,
    ids: [],
    clientIds: [],
  })

  watch(filters, async () => {
    page.value = 1
    await loadUsersNest({})
  }, { deep: true })

  watch(page, async () => {
    await loadUsersNest({})
  })

  const getUsersAsOptions = computed(() => {
    return users.value.map((item) => {
      const function_name = item?.function ?? ''
      const function_suffix = function_name ? ` - ${function_name}` : ''
      return {
        label: `${item.firstName} ${item.lastName}${function_suffix}`,
        value: item.id,
      }
    })
  })

  const getUserProducers = computed(() => {
    // @ts-expect-error producers is present in RecyclerGraphQL
    return user.value?.client?.producers?.collection ?? []
  })

  const activeUsers = computed(() => {
    return users.value.filter(u => u.disabled === false)
  })

  const disabledUsers = computed(() => {
    return users.value.filter(u => u.disabled === true)
  })

  const isAlreadyConnectedToTD = computed(() => {
    if (user?.value?.client?.isConnectedToTrackdechets) {
      return true
    }
    return false
  })

  const isRecycler = computed(() => {
    // @ts-expect-error __typename is accessible in the schema
    return user.value?.client?.__typename === 'RecyclerGraphql'
  })

  const isProducer = computed(() => {
    // @ts-expect-error __typename is accessible in the schema
    return user.value?.client?.__typename === 'ProducerGraphql'
  })

  const isAdmin = computed(() => {
    return user.value?.user?.role === 'admin_app'
  })

  // @ts-expect-error producers is present in RecyclerGraphQL
  const getClientProducers: ComputedRef<ProducerGraphql[]> = computed(() => user?.value?.client?.producers?.collection ?? [])

  const getClientOnboarding: ComputedRef<CompanyOnboardingGraphql | undefined> = computed(() => {
    if (!user.value)
      return undefined
    return (user.value.client as ProducerGraphql).onboarding
  })

  async function loadUsersNest(f: UserFilterGraphql, dontStoreResult = false, pagination?: PaginationArgs): Promise<void | UserGraphql[] | { users: UserGraphql[], count: number }> {
    if (!isAdmin.value)
      return
    const userFilters: UserFilterGraphql = {
      ...((filters.search || f.search) && { search: f.search ?? filters.search }),
      // reset search filters as it collides with user ids for update
      ...(f.ids && { ids: f.ids, search: '' }),
      ...(f.clientIds && { clientIds: f.clientIds }),
    }

    const { data, errors } = await query({
      query: USERS_NEST_QUERY_LIST,
      variables: {
        pagination: pagination || ({ limit: 10, offset: offset.value } as PaginationArgs),
        filters: userFilters,
      },
    })

    if (errors && errors.length > 0) {
      throw errors
    }

    if (dontStoreResult) {
      return { users: data.users.collection as UserGraphql[], count: data.users.count }
    }

    usersCount.value = data!.users.count
    users.value = JSON.parse(JSON.stringify(data.users.collection)) as UserGraphql[]

    return data?.users.collection as UserGraphql[]
  }

  async function loadUserById(f: UserFilterGraphql): Promise<Maybe<UserGraphql>> {
    if (!isAdmin.value)
      return
    const userFilters: UserFilterGraphql = {
      ...((filters.search || f.search) && { search: f.search ?? filters.search }),
      // reset search filters as it collides with user ids for update
      ...(f.ids && { ids: f.ids, search: '' }),
    }

    const { data, errors } = await query({
      query: USERS_NEST_QUERY_LIST,
      variables: {
        pagination: { limit: 1, offset: 0 } as PaginationArgs,
        filters: userFilters,
      },
    })

    if (errors && errors.length > 0) {
      throw errors
    }

    userSetForUpdate.value = JSON.parse(JSON.stringify(data.users.collection[0])) as UserGraphql
    return data.users.collection[0]
  }

  async function acceptCGU(): Promise<void> {
    const { errors } = await mutate({
      mutation: ACCEPT_CGU,
    })

    if (errors && errors.length > 0) {
      throw errors
    }
  }

  async function updateUserNest(user: UpdateUserInput) {
    const { data, errors, validationError } = await mutate({ mutation: UPDATE_USER, variables: { input: user } })

    if (errors && errors.length > 0) {
      throw errors
    }

    const userIndex = users.value.findIndex(u => u.id === data?.updateUser?.id)
    if (user) {
      users.value.splice(userIndex, 1, data!.updateUser)
    }

    return { data, errors, validationError }
  }

  async function removePictureProfile(userId: string) {
    const { data, errors } = await mutate({
      mutation: DELETE_STORED_DOCUMENT,
      variables: { input: { id: userId } },
    })

    if (errors && errors.length > 0) {
      throw errors
    }

    user.value?.client?.documents?.collection?.pop()
    return data?.deleteStoredDocument
  }

  async function createUser(payload: CreateUserInput): Promise<{ data: any, validationError: any, errors: any }> {
    const { data, validationError, errors } = await mutate({ mutation: CREATE_USER, variables: { input: payload } })

    if (errors && errors.length > 0) {
      throw errors
    }

    await loadUsersNest({})
    return { data, validationError, errors }
  }

  async function toggleTrackDechetAutosign() {
    const { data, errors } = await mutate({ mutation: TOGGLE_TRACKDECHETS_AUTOSIGN })

    if (errors && errors.length > 0) {
      throw errors
    }

    return data
  }

  return {
    users,
    page,
    offset,
    usersCount,
    userSetForUpdate,
    getUserProducers,
    isAlreadyConnectedToTD,
    filters,
    getClientOnboarding,
    isRecycler,
    getClientProducers,
    isProducer,
    isAdmin,
    getUsersAsOptions,
    activeUsers,
    disabledUsers,
    createUser,
    loadUsersNest,
    loadUserById,
    acceptCGU,
    updateUserNest,
    toggleTrackDechetAutosign,
    removePictureProfile,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUsersStore, import.meta.hot))
}
