// modules/i18n.ts
import { createI18n } from 'vue-i18n'

const messages = Object.fromEntries(
  Object.entries(import.meta.glob<{ default: Record<string, any> }>('../../../locales/*.json', { eager: true }))
    .map(([key, value]) => {
      const json = key.endsWith('.json')
      return [key.slice(17, json ? -5 : -4), value?.default]
    }),
)

export const i18n = createI18n({
  legacy: false,
  locale: lang.value,
  globalInjection: true,
  messages,
})
