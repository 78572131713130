import type { NavigationGuard } from 'vue-router'
import { getAuth } from 'firebase/auth'

export const authGuard: NavigationGuard = async (to, from, next) => {
  const { isAdmin, user } = storeToRefs(useAuthStore())
  const { getCurrentUser } = useAuthStore()
  const urlSearchParams = new URLSearchParams(window.location.search)
  const query = Object.fromEntries(urlSearchParams.entries())

  try {
    // Wait for Firebase to initialize auth state
    if (!authState.initialized) {
      // Wait for a short period to see if Firebase initializes
      await new Promise(resolve => setTimeout(resolve, 1000))
      if (!authState.initialized) {
        // If still not initialized after the timeout, redirect to login
        return next({
          path: '/auth/login',
          query,
        })
      }
    }

    // If user isn't loaded yet, try to fetch it
    if (!user.value && getAuth().currentUser) {
      await getCurrentUser()
    }

    if (to.matched.some(record => record.meta.requiresAdmin)) {
      if (!user.value || !isAdmin.value) {
        return next({
          path: '/auth/login',
          query,
        })
      }
    }
    else if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!user.value) {
        return next({
          path: '/auth/login',
          query,
        })
      }
    }

    next()
  }
  catch {

  }
}
