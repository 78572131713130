import type { User } from 'firebase/auth'
import type { provider } from '~/stores/auth'
import { beforeAuthStateChanged, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth'
import { fb_auth } from '~/services/firebase'
import { AuthStatus } from '~/stores/auth'

let unsubBeforeAuth: any = null
let unsubAuthState: any = null

export async function firebaseAuthObserver() {
  let isNewUser = false

  // Keep a single instance of the unsubscribe function
  let unsubAuthState = null

  // Handle before auth state changes
  const unsubBeforeAuth = beforeAuthStateChanged(fb_auth, async (user: User | null) => {
    // TODO : If user signed using SSO and did not finish the creation process
    // Firebase user is created but not in our DB
    try {
      if (user) {
        beforeAuthChangedUser.value = user
        isNewUser = user.metadata.creationTime === user.metadata.lastSignInTime
        const provider = user.providerId as provider
        authState.provider = provider
        // New user and provider equals firebase then SIGN_UP
        // User that already sign in using SSO or classical SIGN_UP depending of the provider will be SIGN_IN / SSO_SIGN_IN
        authState.state = isNewUser
          ? provider === 'firebase' ? AuthStatus.SIGN_UP : AuthStatus.SSO_SIGN_UP
          : provider === 'firebase' ? AuthStatus.SIGN_IN : AuthStatus.SSO_SIGN_IN
      }
    }
    catch (error) {
      console.error('Error in beforeAuthStateChanged:', error)
      authState.state = AuthStatus.SIGNED_OUT
    }
  })

  // Handle auth state changes
  unsubAuthState = onAuthStateChanged(fb_auth, async (user: User | null) => {
    try {
      authState.initialized = true
      if (user && ![AuthStatus.SSO_SIGN_UP, AuthStatus.SIGN_UP].includes(authState.state)) {
        // On sign up, firebaseUserPopulated is true after the signup in authStore
        // Otherwise user is not yet created in our DB
        authState.firebaseUserPopulated = true
        authState.state = AuthStatus.SIGNED_IN
      }
      else if (!user) {
        authState.firebaseUserPopulated = false
        authState.state = AuthStatus.SIGNED_OUT
      }
    }
    catch (error) {
      console.error('Error in onAuthStateChanged:', error)
      authState.firebaseUserPopulated = false
      authState.state = AuthStatus.SIGNED_OUT
    }
  })

  return () => {
    unsubBeforeAuth && unsubBeforeAuth()
    unsubAuthState && unsubAuthState()
  }
}

// Separate cleanup function
export function cleanupFirebaseAuthObserver() {
  if (unsubBeforeAuth) {
    unsubBeforeAuth()
    unsubBeforeAuth = null
  }
  if (unsubAuthState) {
    unsubAuthState()
    unsubAuthState = null
  }
}

export async function signIn(email: string, password: string) {
  authState.state = AuthStatus.SIGN_IN
  await signInWithEmailAndPassword(fb_auth, email, password)
}

export async function logout() {
  const { user } = storeToRefs(useAuthStore())
  const { resetFilters } = useCollectsStore()
  if (resetFilters)
    resetFilters()
  // Reset token and current user on logout
  token.value = ''
  // @ts-expect-error user is of type CurrentUserGraphql
  user.value = undefined
  // await resetApolloCache()
  useFakeData.value = false
  selectedTab.value = 0
  authState.firebaseUserPopulated = false
  authState.state = AuthStatus.SIGNED_OUT
  await fb_auth.signOut()
}
