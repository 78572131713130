import type {
  BsdGraphql,
  CancelCollectInputGraphql,
  CollectGraphql,
  CollectGraphqlFilters,
  CollectsCollection,
  CollectStatus,
  CreateCollectGraphql,
  Maybe,
  Mutation,
  PartialUserGraphql,
  SignAllCollectBsDsInput,
  SignBsdInput,
  UpdateCollectInformationsGraphql,
} from '~/types/graphql-backend-types/gql-types'
import { useGqlMikro } from '~/composables/useGqlMikro'
import {
  BO_COLLECTS_QUERY_LIST,
  CANCEL_COLLECT,
  COLLECTS_QUERY_LIST,
  COLLECTS_WITH_MISSING_FIELDS,
  CREATE_COLLECT_QUERY,
  GET_REFERENTS,
  SIGN_ALL_BSDS,
  SIGN_BSD,
  UPDATE_COLLECT_QUERY,
} from '~/queries/collects'
import {
  AscDescGraphql,
  DocumentContext,
  DocumentMetaSubtypes,
  DocumentType,
} from '~/types/graphql-backend-types/gql-types'

export interface MaterialContainerChoice {
  materialId: string
  containers: {
    id: string
    containerId: string
    documents: {
      previewUrls: string[]
      files: File[]
    }
  }[][]
}

export const useCollectsStore = defineStore('collects', () => {
  const { query, mutate } = useGqlMikro()
  const { getSelectedClient } = storeToRefs(useClientsStore())
  const { collect } = storeToRefs(useCollectStore())
  const { selectedOrgId } = storeToRefs(useOrganizationStore())
  const { user, isAdmin } = storeToRefs(useAuthStore())
  const { uploadDocument, persistDocument } = useDocumentStore()

  const collects = ref<CollectsCollection>({} as CollectsCollection)
  const collectsCatchOn = ref<CollectGraphql[]>([] as CollectGraphql[])
  const referents = ref<PartialUserGraphql[]>([])
  const materialContainerChoiceBeforeCreation = ref<MaterialContainerChoice[]>([])

  const preCollectCreation: CreateCollectGraphql = reactive({
    siteId: '',
    notes: '',
    startsAt: '',
    endsAt: '',
    containers: [],
    referentUserId: '',
    clientId: '',
  })

  const page = ref<number>(1)
  const collectsLoading = ref<boolean>(true)
  const offset = computed(() => (page.value - 1) * 10)

  const referentName = ref<string>('')

  const filters: CollectGraphqlFilters = reactive({
    status: [] as CollectStatus[],
    orderBy: {
      field: 'startsAt',
      order: AscDescGraphql.Desc,
    },
    clientIds: [],
    search: '',
    organizationIds: [],
  })

  const isEmptyFilters = computed(() => {
    return (
      filters.status!.length === 0
      && filters.clientIds!.length === 0
      && filters.search === ''
    )
  })

  watch(filters, async () => {
    page.value = 1
    if (!user.value)
      return
    if (isAdmin.value) {
      await loadBOCollects()
    }
    else {
      await loadCollects()
    }
  }, { deep: true })

  watch(page, async () => {
    if (!user.value) {
      return
    }
    if (isAdmin.value) {
      await loadBOCollects()
    }
    else {
      await loadCollects()
    }
  })

  const collectsCount = computed(() => collects.value.count)

  const getCollects: ComputedRef<CollectGraphql[] | []> = computed(() => {
    if (collects?.value?.collection) {
      return collects.value.collection as CollectGraphql[]
    }
    return []
  }) as ComputedRef<CollectGraphql[] | []>

  const getCollectsAsOptions = computed(() =>
    collects.value?.collection?.map(item => ({
      label: item?.__typename,
      value: item?.id,
    })),
  )

  // TODO : Need to change this ASAP
  async function loadBOCollects() {
    const client = getSelectedClient.value
    if (!user.value)
      return

    collectsLoading.value = true
    const { data, errors } = await query({
      query: BO_COLLECTS_QUERY_LIST,
      variables: {
        pagination: { limit: 10, offset: offset.value },
        filters: {
          ...(client && client.id && { clientIds: [client.id] }),
          orderBy: { field: 'startsAt', order: filters.orderBy?.order },
          ...(filters.status && filters.status.length > 0 && { status: filters.status }),
          ...(selectedOrgId.value && { organizationIds: [selectedOrgId.value] }),
        } as CollectGraphqlFilters,
      },
    })

    if (errors && errors.length > 0) {
      throw errors
    }

    collectsLoading.value = false
    collects.value = JSON.parse(JSON.stringify(data.collects))
    return data
  }

  async function loadCollects() {
    if (!user.value)
      return

    collectsLoading.value = true

    const { data, errors } = await query({
      query: COLLECTS_QUERY_LIST,
      variables: {
        pagination: { limit: 10, offset: offset.value },
        filters: {
          clientIds: filters.clientIds!.length > 0 ? filters.clientIds : null,
          orderBy: { field: 'startsAt', order: filters.orderBy?.order },
          status: filters.status!.length > 0 ? filters.status : null,
          search: filters.search ?? null,
        },
      },
    })

    if (errors && errors.length > 0) {
      throw errors
    }

    collectsLoading.value = false
    collects.value = JSON.parse(JSON.stringify(data.collects))
    return data
  }

  async function collectsWithMissingFields() {
    if (!user.value)
      return

    collectsLoading.value = true

    const { data, errors } = await query({
      query: COLLECTS_WITH_MISSING_FIELDS,
      variables: { pagination: { limit: 100, offset: 0 } },
    })

    if (errors && errors.length > 0) {
      throw errors
    }

    collectsLoading.value = false
    collectsCatchOn.value = JSON.parse(JSON.stringify(data.collectsWithMissingFields))
    return data
  }

  function formatContainerData(materials: MaterialContainerChoice[], addDocuments: boolean = false): { materialId: string, containerId: string, files?: File[] }[] {
    return materials.map((materials) => {
      return materials.containers.flat().map((container) => {
        const data: { materialId: string, containerId: string, files?: File[] } = { materialId: materials.materialId, containerId: container.containerId }

        if (addDocuments) {
          data.files = container.documents.files
        }
        return data
      }).flat()
    }).flat()
  }

  async function createCollect(): Promise<{ errors?: readonly any[], data?: CollectGraphql }> {
    const { data, errors } = await mutate({
      mutation: CREATE_COLLECT_QUERY,
      variables: {
        input: {
          ...preCollectCreation,
          containers: formatContainerData(materialContainerChoiceBeforeCreation.value),
          clientId: user.value.client.id,
        },
      },
    })

    if (errors && errors.length > 0) {
      throw errors
    }

    // * Upload collected container documents
    await uploadCollectedContainerDocuments(formatContainerData(materialContainerChoiceBeforeCreation.value, true), data!.createCollect)

    resetPrecollectCreation()

    return { data: data?.createCollect }
  }

  async function uploadCollectedContainerDocuments(
    collectedContainers: { materialId: string, containerId: string, files?: File[], uploaded?: boolean }[],
    createdCollect: CollectGraphql,
  ): Promise<void> {
    for (const cc of createdCollect?.collectedContainers.collection) {
      const collectedContainerId = cc?.id as string
      const ccMaterialId = cc?.material?.id
      const ccContainerId = cc?.container?.id

      const collectedContainer = collectedContainers.find((c) => {
        return c.materialId === ccMaterialId
          && c.containerId === ccContainerId && !('uploaded' in c)
      })
      const { files } = collectedContainer!

      if (!files)
        continue

      for (const file of files) {
        try {
          await uploadDocument({ file })

          const persistDocumentPayload = {
            file,
            clientId: user.value.client.id,
            context: {
              id: collectedContainerId,
              type: DocumentContext.CollectedContainer,
              subtype: DocumentMetaSubtypes.Uncategorized,
            },
            type: DocumentType.Picture,
          }

          await persistDocument(persistDocumentPayload)
        }
        catch {
          console.error('Error uploading document')
        }
      }
      if (collectedContainer) {
        collectedContainer.uploaded = true
      }
    }
  }
  async function updateCollectInformations(payload: UpdateCollectInformationsGraphql): Promise<Mutation['updateCollectInformations']> {
    const { data, errors } = await mutate({
      mutation: UPDATE_COLLECT_QUERY,
      variables: { input: payload },
    })

    if (errors && errors.length > 0) {
      throw errors
    }

    collect.value.status = data!.updateCollectInformations.status
    collect.value.startsAt = data!.updateCollectInformations.startsAt
    collect.value.transportPrice = data!.updateCollectInformations.transportPrice
    collect.value.licensePlate = data!.updateCollectInformations.licensePlate

    return data!.updateCollectInformations
  }

  async function updateCollectStatus(payload: { collectId: string, status: CollectStatus }): Promise<Mutation['updateCollectInformations']> {
    const { data, errors } = await mutate({
      mutation: UPDATE_COLLECT_QUERY,
      variables: { input: payload },
    })

    if (errors && errors.length > 0) {
      throw errors
    }

    collect.value.status = data!.updateCollectInformations.status

    findAndUpdateCollectStatus(payload.collectId, data!.updateCollectInformations.status)
    return data!.updateCollectInformations
  }

  function findAndUpdateCollectStatus(collectId: string, status: CollectStatus) {
    const coco = collects.value?.collection?.find(item => item?.id.toString() === collectId)
    if (!coco)
      return
    coco.status = status
  }

  function resetPrecollectCreation() {
    preCollectCreation.clientId = user.value.client.id
    preCollectCreation.siteId = ''
    preCollectCreation.referentUserId = ''
    preCollectCreation.containers = []
    materialContainerChoiceBeforeCreation.value = []
    preCollectCreation.notes = ''
    preCollectCreation.startsAt = ''
    preCollectCreation.endsAt = ''
    referentName.value = ''
  }

  async function getReferents() {
    const { data, errors } = await query({ query: GET_REFERENTS })

    if (errors && errors.length > 0) {
      throw errors
    }

    referents.value = data.referents.collection as PartialUserGraphql[]
  }

  function getCollectById(id: string): Maybe<CollectGraphql> | undefined {
    return collects.value.collection.find(item => item?.id.toString() === id.toString())
  }

  function updateBSD(payload: { collectId: string, BSD: BsdGraphql }) {
    const { BSD, collectId } = payload
    const collect = collects.value.collection.find(item => item?.id.toString() === collectId.toString())
    if (collect)
      collect.BSDs.collection[0] = BSD
  }

  async function cancelCollect(id: string): Promise<Mutation['cancelCollect']> {
    const { data, errors } = await mutate({
      mutation: CANCEL_COLLECT,
      variables: { input: { collectId: id } as CancelCollectInputGraphql },
    })

    if (errors && errors.length > 0) {
      throw errors
    }

    return data!.cancelCollect
  }

  async function signBSD(collectId: string, collectedContainerIds: string) {
    const input: SignBsdInput = {
      collectedContainerIds,
      collectId,
      signatureCode: 1,
    }

    const { data, errors } = await mutate({
      mutation: SIGN_BSD,
      variables: { input },
    })

    if (errors && errors.length > 0) {
      throw errors
    }

    return { data, errors }
  }

  async function signAllBSDs(collectId: string) {
    const input: SignAllCollectBsDsInput = {
      collectId,
      signatureCode: 1,
    }

    const { data, errors } = await mutate({
      mutation: SIGN_ALL_BSDS,
      variables: { input },
    })

    if (errors && errors.length > 0) {
      throw errors
    }

    return { data, errors }
  }

  function resetFilters() {
    filters.status = []
    filters.orderBy = { field: 'startsAt', order: AscDescGraphql.Asc }
    filters.clientIds = []
    filters.organizationIds = []
    filters.search = ''
  }

  return {
    filters,
    page,
    offset,
    collects,
    collectsCatchOn,
    referents,
    isEmptyFilters,
    preCollectCreation,
    materialContainerChoiceBeforeCreation,
    collectsLoading,
    collectsCount,
    getCollects,
    referentName,
    getCollectsAsOptions,
    createCollect,
    updateCollectInformations,
    updateCollectStatus,
    loadCollects,
    collectsWithMissingFields,
    loadBOCollects,
    resetFilters,
    getCollectById,
    updateBSD,
    cancelCollect,
    getReferents,
    signBSD,
    signAllBSDs,
    formatContainerData,
    resetPrecollectCreation,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCollectsStore, import.meta.hot))
}
