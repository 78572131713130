import type { ClientCustomDashboardInputGraphql, LegacyDataImportInput } from '~/types/graphql-backend-types/gql-types'
import { DASHBOARD_QUERY, IMPORT_LEGACY_DATA } from '~/queries/dashboard'

export function useDashboard() {
  const { query, mutate } = useGqlMikro()

  // clientId must be passed if the user is an admin
  const getDashboardUrl = async (input: ClientCustomDashboardInputGraphql) => {
    const { data } = await query({
      query: DASHBOARD_QUERY,
      variables: { input },
    })
    return data.getDashboardUrl
  }

  const importLegacyData = async (input: LegacyDataImportInput): Promise<void> => {
    const { errors } = await mutate({
      mutation: IMPORT_LEGACY_DATA,
      variables: { input },
    })

    if (errors && errors.length > 0) {
      throw errors
    }
  }

  return { getDashboardUrl, importLegacyData }
}
