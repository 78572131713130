<script setup lang="ts">
import { VERSION } from '@cinqo/common'
import { RotateCcwIcon } from 'lucide-vue-next'
import { hotReload } from '~/helpers/UtilsHelper'

const { loading = true } = defineProps<{
  loading: boolean
}>()

const { getCurrentUser } = useAuthStore()
const { t } = useI18n()
const router = useRouter()
const path = computed(() => location.pathname)
const userloading = ref<boolean>(false)

async function hotReloadApp() {
  await hotReload()
}

watch(() => authState.firebaseUserPopulated, async (isInit: boolean) => {
  if (isInit) {
    userloading.value = true
    await getCurrentUser()
    // Check if we have a specific onboarding path to redirect to
    if (authState.onboardingRedirectPath) {
      const redirectPath = authState.onboardingRedirectPath
      // Reset the path immediately to prevent duplicate redirects
      authState.onboardingRedirectPath = null

      // Navigate to the specified path
      await router.push(redirectPath)
    }
    else {
      // Use another enum for SIGNED_IN apollo
      // Redirict to current path
      // Keep the query parameters when redirecting
      const queryParams = new URLSearchParams(window.location.search)
      const queryObject = Object.fromEntries(queryParams.entries())

      const targetPath = path.value !== '/auth/login' ? path.value : '/'

      // Preserve the current query parameters
      await router.push({
        path: targetPath,
        query: queryObject, // This preserves all query parameters
      })
    }

    userloading.value = false
  }
}, { immediate: true })
</script>

<template>
  <div v-if="loading || userloading" class="h-full w-full flex flex-col items-center justify-center">
    <img :src="picto" alt="Cinqo logo" class="h-14 w-14 animate-ping">
    <div class="pt-20 text-xl text-gray font-mono">
      {{ t('global.loading') }} ...
    </div>
    <div class="absolute bottom-20 cursor-pointer align-bottom text-base-content" @click="hotReloadApp">
      <RotateCcwIcon :size="32" />
      <span>{{ VERSION }}</span>
    </div>
  </div>

  <slot v-else />
</template>
