import gql from "graphql-tag";

export const SIGN_UP = gql`
  mutation SignUp($input: SignUpInputGraphql!) {
    signUp(input: $input) {
      id
    }
  }
`;

export const GET_OVERLAYED_CLIENT_TOKEN = gql`
  mutation LogAs($input: LogAsInput!) {
    logAs(input: $input) {
      userJWT
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query CurrentUser(
    $input: ClientCustomDashboardInputGraphql
    $onboardingFilters: CompanyOnboardingInputGraphql
  ) {
    me {
      organization {
        id
        treatmentCodes {
          code
          description
          isRemapped
          ... on CustomTreatmentCodeGraphql {
            customTreatmentKind
          }
          ... on RegularTreatmentCodeGraphql {
            treatmentKind
          }
        }
      }
      client {
        __typename
        id
        name
        isConnectedToTrackdechets
        autosignEnabledForTrackdechets
        customDashboard(input: $input)
        hasCollects
        companyLogo {
          id
          name
          signedUrl
        }
        sites {
          collection {
            address
            id
            name
            zipCode
          }
          count
        }
        ... on RecyclerGraphql {
          isPremiumRecycler
          producers {
            collection {
              id
              name
              sites {
                collection {
                  address
                  name
                  id
                  zipCode
                }
              }
            }
          }
        }
        ... on ProducerGraphql {
          onboardingDone
          onboardingNeeds
          onboarding(filters: $onboardingFilters) {
            done
            onboardingItems {
              done
              key
              value
            }
          }
          recyclers {
            collection {
              isPremiumRecycler
              id
              name
              siret
              address {
                line1
                country
                city
              }
              sites {
                collection {
                  address
                  name
                  id
                  zipCode
                }
              }
            }
          }
        }
      }
      user {
        email
        firstName
        id
        lastName
        role
        acceptedTermsAt
      }
    }
  }
`;
